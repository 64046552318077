<template>
  <v-container fluid>
    <v-card>
      <FormToolbar :title="isClientExist ? 'Картка клієнта' : 'Вибачте, такого клієнта в цій компанії немає'">
        <template v-if="isClientExist" v-slot:right>
          <div class="mr-md-2">
            <BonusPayroll
              :user-id="client.user_id"
              @yes="loadBonuses"
              button-text="Нарахувати бонуси"
              bonus-action-type="plusBonus"
            />
          </div>
          <div class="mt-2 mt-md-0">
            <BonusPayroll
              :user-id="client.user_id"
              @yes="loadBonuses"
              button-text="Відрахувати бонуси"
              bonus-action-type="minusBonus"
            />
          </div>
        </template>

        <template v-slot:extension>
          <v-tabs v-if="isClientExist" v-model="currentTab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab v-for="(item, index) in tabs" :key="index">
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </template>
      </FormToolbar>

      <v-tabs-items v-if="isClientExist" v-model="currentTab">
        <v-tab-item v-for="(item, index) in tabs" :key="index">
          <v-card flat color="blue-grey lighten-5">
            <component
              v-if="isClientExist"
              :is="item.component"
              :client="client"
              :user-id="client.user_id"
              :bonuses="bonuses"
              :is-loaded="isLoaded"
            ></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-card v-if="currentTabName === 'info' && isClientExist" class="mt-5" flat>
      <Logs :preselected-users="[{ ...this.client, id: this.client.user_id }]" disable-users />
    </v-card>

    <v-card v-if="currentTabName === 'bonuses'" class="mt-5" flat>
      <Payments />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import FormToolbar from '@/components/common/forms/FormToolbar'
import PurchaseTable from '@/components/support/users/PurchaseTable'
import Logs from '@/components/common/Logs'
import setLogTabs from '@/mixins/setLogTabs'

export default {
  name: 'ClientCard',

  components: {
    Logs,
    PurchaseTable,
    FormToolbar,
    ProgressCircular: () => import('@/components/common/ProgressCircular'),
    SpoilerElement: () => import('@/components/common/SpoilerElement'),
    BonusPayroll: () => import('@/components/marketing/clients/BonusPayroll'),
    GeneralInfo: () => import('@/components/marketing/clients/GeneralInfo'),
    Bonuses: () => import('@/components/common/users/Bonuses'),
    Payments: () => import('@/components/common/Payments'),
  },

  mixins: [setLogTabs],

  data() {
    return {
      isLoaded: false,
      tabs: [
        {
          name: 'info',
          title: 'Основні дані',
          component: 'GeneralInfo',
        },
        {
          name: 'bonuses',
          title: 'Бонуси',
          component: 'Bonuses',
        },
      ],
      currentTab: 0,
      payload: {},
    }
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {
    this.clearData()
  },

  watch: {
    currentTab(val) {
      if (val === 1) {
        this.isLoaded = false
      }
    },
  },

  computed: {
    ...mapState('users', ['client']),
    ...mapState('bonuses', ['bonuses']),

    currentCompanyId() {
      return this.$route.params.id
    },
    clientId() {
      return +this.$route.params.clientId
    },
    isClientExist() {
      return Object.entries(this.client).length
    },
    currentTabName() {
      return this.tabs[this.currentTab].name
    },
  },

  methods: {
    ...mapActions('users', ['loadSelectedClient']),
    ...mapActions('bonuses', ['loadUserBonuses']),
    ...mapMutations('users', ['SET_SELECTED_CLIENT']),

    async initialize() {
      this.payload = {
        companyId: this.currentCompanyId,
        userId: this.clientId,
      }
      try {
        if (!this.isClientExist) await this.loadSelectedClient(this.payload)
        if (this.isClientExist) await this.loadBonuses()
      } finally {
        this.isLoaded = true
      }
    },

    async loadBonuses() {
      await this.loadUserBonuses(this.payload)
    },

    clearData() {
      this.SET_SELECTED_CLIENT({})
    },
  },
}
</script>
